<template>
	<div>
		<h1>{{value.title}}</h1>
		<div v-html="processHTML(value.content)"></div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Object,
				required: true
			},
			global_style: {
				required: true
			}
		},
		components: {
		},
	}
</script>
